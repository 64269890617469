import React from "react";
import Helmet from "react-helmet";
import { Link, graphql } from "gatsby";

import { TagLink } from "src/components/01_atoms/TagLink";
import { Header } from "src/components/03_organisms/Header";
import { Footer } from "src/components/03_organisms/Footer";
import { Bio } from "src/components/02_molecules/Bio";
import { Layout } from "src/components/Layout";
import ogPic from "src/assets/images/ogp.png";
import styles from "./style.module.css";

const BlogPostTemplate = (props) => {
  const siteMetadata = props.data.site.siteMetadata;
  const post = props.data.markdownRemark;
  const siteTitle = siteMetadata.title;
  const postUrl = siteMetadata.siteUrl + post.fields.slug;
  const siteDescription = post.excerpt;
  const { previous, next } = props.pageContext;
  const ogpimageUrl =
    post.frontmatter.ogpimage === "" || post.frontmatter.ogpimage === null
      ? ogPic
      : "/asset/ogp/" + post.frontmatter.ogpimage;

  return (
    <Layout>
      <Header isHome={false} />
      <Helmet
        htmlAttributes={{ lang: "ja" }}
        meta={[
          { name: "description", content: siteDescription },
          // { name: 'keywords', content: post.frontmatter.title + ',' +post.frontmatter.category+','+post.frontmatter.tags.join(',')+data.site.siteMetadata.keywords},
          {
            property: "og:title",
            content: post.frontmatter.title + " | " + siteMetadata.title,
          },
          { property: "og:description", content: siteDescription },
          { property: "og:type", content: "website" },
          { property: "og:site_name", content: siteMetadata.title },
          { property: "og:url", content: postUrl },
          { property: "twitter:card", content: "summary_large_image" },
          { property: "twitter:site", content: siteMetadata.twitterUsername },
          // { property: 'google-site-verification', content: data.site.siteMetadata.google_site_verification},
          {
            property: "og:image",
            content: siteMetadata.siteUrl + ogpimageUrl,
          },
        ]}
        title={`${post.frontmatter.title} | ${siteTitle}`}>
        <script
          data-ad-client="ca-pub-8068277400499023"
          async
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>
      </Helmet>
              <div className={styles.root}>
      <h1 className={styles.descTitle}>{post.frontmatter.title}</h1>
      <small className={styles.descItemDate}>{post.frontmatter.date}</small>
      <div>
        <img
          className={styles.descContentsImage}
          src={ogpimageUrl}
          width="900px"
        />
      </div>
      <div className={styles.tag}>
        {post.frontmatter.tags.map((value, id) => (
          <TagLink to={value} text={value} id={id} />
        ))}
      </div>

      <div
        className={styles.descContents}
        dangerouslySetInnerHTML={{ __html: post.html }}
      />
      <Bio />
      <div className={styles.tag}>
        {post.frontmatter.tags.map((value, id) => (
          <TagLink to={value} text={value} id={id} />
        ))}
      </div>

      <ul className={styles.nextComponent}>
        <li className={styles.nextBox}>
          {previous && (
            <Link
              to={previous.fields.slug}
              rel="prev"
              className={styles.nextLink}>
              <div className={styles.descItemImageArea}>
                {previous.frontmatter.ogpimage === "" ||
                previous.frontmatter.ogpimage === null ? (
                  <img className={styles.descItemImage} src={ogPic} />
                ) : (
                  <img src={"/asset/ogp/" + previous.frontmatter.ogpimage} />
                )}
              </div>
              ← 古い記事
              <br />
              {previous.frontmatter.title}
            </Link>
          )}
        </li>
        <li className={styles.nextBox}>
          {next && (
            <Link to={next.fields.slug} className={styles.nextLink} rel="next">
              <div className={styles.descItemImageArea}>
                {next.frontmatter.ogpimage === "" ||
                next.frontmatter.ogpimage === null ? (
                  <img className={styles.descItemImage} src={ogPic} />
                ) : (
                  <img src={"/asset/ogp/" + next.frontmatter.ogpimage} />
                )}
              </div>
              新しい記事 →<br />
              {next.frontmatter.title}
            </Link>
          )}
        </li>
      </ul>
              </div>
      <Footer />
    </Layout>
  );
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
        description
        siteUrl
        twitterUsername
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(truncate: true)
      html
      frontmatter {
        title
        date(formatString: "YYYY/MM/DD")
        tags
        ogpimage
      }
      fields {
        slug
      }
    }
  }
`;
